import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ScrollBtn from "../components/scroll-btn"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const AboutUsPage = props => {
  const {
    Title,
    IntroText,
    Section,
    OurValues,
    OurClientsSection,
    localizations,
    locale,
  } = props.data.strapiAboutUsPage

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        locale={locale}
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={localizations.data[0].attributes}
        contactSlug={props.data.strapiContact.slug}
        currentPage={props.data.strapiAboutUsPage.slug}
      />
      <Layout>
        <Seo
          lang={locale}
          description={IntroText.data.IntroText}
          title={Title}
        />
        <section className="single-page__hero flex flex-col justify-center lg:min-h-[720px] px-6 relative">
          <div className="text-center my-8">
            <div>
              <div className="hero-circles-container">
                <div className="circle circle--dashed circle--1 circle--1-dashed"></div>
                <div className="circle circle--dashed circle--2 circle--2-dashed"></div>
                <div className="circle circle--dashed circle--3 circle--3-dashed"></div>
                <div className="circle circle--dashed circle--4 circle--4-dashed"></div>
              </div>
            </div>
            <h1 className="single-page__hero-title">{Title}</h1>

            <div className="single-page__hero-text">
              <ReactMarkdown>{IntroText.data.IntroText}</ReactMarkdown>
            </div>
          </div>
          <div className="scrollButtonContainer">
            <ScrollBtn
              beforeText={
                props.data.strapiGeneralTranslation.ScrollDownOrJustUse
              }
              afterText={props.data.strapiGeneralTranslation.Keys}
            />
          </div>
        </section>

        <div className="sections-section">
          <div className="sections">
            {Section.map((section, index) => {
              return (
                <section key={index} className="section">
                  <div className="section__image-wrapper">
                    <img
                      className="section__image"
                      src={section.Image.url}
                      alt={section.Image.alternativeText}
                      loading="lazy"
                    />
                  </div>
                  <div className="section__content-wrapper">
                    <h2 className="section__title">{section.Headline}</h2>
                    <div className="section__text">
                      <ReactMarkdown>{section.Text.data.Text}</ReactMarkdown>
                    </div>
                  </div>
                </section>
              )
            })}
          </div>
        </div>
        <section className="our-values-section">
          <div className="our-values">
            <p className="our-values__subtitle">{OurValues.Subtitle}</p>
            <h2 className="our-values__title">{OurValues.Title}</h2>
            <div className="our-values__values">
              {OurValues.ValuesList.map((value, index) => {
                return (
                  <div key={index} className="value">
                    <img
                      className="value__image"
                      src={value.Image.url}
                      alt={value.Image.alternativeText}
                      loading="lazy"
                    />
                    <h2 className="value__title">{value.Heading}</h2>
                    <p className="value__text">{value.Description}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="our-clients-section">
          <div className="our-clients__wrapper">
            <div className="our-clients">
              <p className="our-clients__subtitle">
                {OurClientsSection.Subtitle}
              </p>
              <h2 className="our-clients__title">
                {OurClientsSection.Headline}
              </h2>
              <div className="our-clients__images">
                {OurClientsSection.ClientImages.map((client, index) => {
                  return (
                    <img
                      key={index}
                      className="our-clients__image"
                      src={client.TechImage.url}
                      alt={client.TechImage.alternativeText}
                      loading="lazy"
                    />
                  )
                })}
              </div>
            </div>
            <div className="testimonial">
              <div className="testimonial__text">
                <ReactMarkdown>
                  {OurClientsSection.Testimonial.Text.data.Text}
                </ReactMarkdown>
              </div>
              <p className="testimonial__name">
                {OurClientsSection.Testimonial.Name}
              </p>
              <p className="testimonial__position">
                {OurClientsSection.Testimonial.Position}
              </p>
            </div>
          </div>
        </section>
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
        locale={locale}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const query = graphql`
  query ($slug: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    strapiAboutUsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      Title
      locale
      slug
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      IntroText {
        data {
          IntroText
        }
      }
      Section {
        Headline
        Text {
          data {
            Text
          }
        }
        Image {
          url
          alternativeText
        }
      }
      OurValues {
        Subtitle
        Title
        ValuesList {
          Image {
            url
            alternativeText
          }
          Description
          Heading
        }
      }
      OurClientsSection {
        Subtitle
        Headline
        ClientImages {
          TechImage {
            url
            alternativeText
          }
        }
        Testimonial {
          Name
          Position
          Text {
            data {
              Text
            }
          }
        }
      }
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }

    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }
  }
`

export default AboutUsPage
