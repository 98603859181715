import * as React from "react"

function ScrollBtn({
  beforeText,
  afterText,
  circleColor = "linear-gradient(180deg, #bd023a 52%, #d50342 93%)",
  textColor = "#636885",
  arrowColor = "#ffffff",
}) {
  const handleClick = () => {
    window.scrollTo(0, 940)
  }

  return (
    <div
      className="scroll-btn__wrapper"
      role={"button"}
      tabIndex="0"
      onClick={() => handleClick()}
    >
      <button
        className="scroll-btn"
        aria-label="Scroll down"
        style={{ backgroundImage: circleColor }}
      >
        <svg className="downArrow" width="14" height="18" viewBox="0 0 15 19">
          <line
            x1="1"
            y1="11"
            x2="7.705"
            y2="17.705"
            stroke={arrowColor}
            strokeWidth="2"
          />
          <line
            x1="13"
            y1="11"
            x2="7"
            y2="17"
            stroke={arrowColor}
            strokeWidth="2"
          />
          <line
            x1="7"
            y1="0"
            x2="7"
            y2="16"
            stroke={arrowColor}
            strokeWidth="2"
            className="arrowLine"
          />
        </svg>
      </button>
      <div className="scroll-btn__text">
        <p style={{ color: textColor }}>{beforeText}</p>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.2827 6.69272C20.4093 8.01181 20.5 9.3577 20.5 10.5C20.5 11.6423 20.4093 12.9882 20.2827 14.3073C20.1532 15.6572 20.0598 16.6186 19.8788 17.3717C19.7029 18.1036 19.4522 18.6002 19.0262 19.0262C18.6002 19.4522 18.1036 19.7029 17.3717 19.8788C16.6186 20.0598 15.6572 20.1532 14.3073 20.2827C12.9882 20.4093 11.6423 20.5 10.5 20.5C9.3577 20.5 8.01181 20.4093 6.69272 20.2827C5.34276 20.1532 4.38139 20.0598 3.6283 19.8788C2.89638 19.7029 2.39979 19.4522 1.9738 19.0262C1.5478 18.6002 1.29712 18.1036 1.1212 17.3717C0.940193 16.6186 0.846812 15.6572 0.717287 14.3073C0.590723 12.9882 0.5 11.6423 0.5 10.5C0.5 9.3577 0.590723 8.01181 0.717287 6.69272C0.846812 5.34276 0.940193 4.38139 1.1212 3.6283C1.29712 2.89638 1.5478 2.39979 1.9738 1.9738C2.39979 1.5478 2.89638 1.29712 3.6283 1.1212C4.38139 0.940193 5.34276 0.846812 6.69272 0.717287C8.01181 0.590723 9.3577 0.5 10.5 0.5C11.6423 0.5 12.9882 0.590723 14.3073 0.717287C15.6572 0.846812 16.6186 0.940193 17.3717 1.1212C18.1036 1.29712 18.6002 1.5478 19.0262 1.9738C19.4522 2.39979 19.7029 2.89638 19.8788 3.6283C20.0598 4.38139 20.1532 5.34276 20.2827 6.69272Z"
            stroke={textColor}
          />
          <path
            d="M6.5 10.5L10.5 14.5L14.5 10.5"
            stroke={textColor}
            strokeWidth="1.2"
          />
          <path d="M10.5 14V7" stroke={textColor} />
        </svg>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.717287 14.3073C0.590723 12.9882 0.5 11.6423 0.5 10.5C0.5 9.3577 0.590723 8.01181 0.717287 6.69272C0.846813 5.34276 0.940193 4.38139 1.1212 3.62831C1.29712 2.89638 1.54781 2.39979 1.9738 1.9738C2.39979 1.54781 2.89638 1.29712 3.62831 1.1212C4.38139 0.940193 5.34276 0.846813 6.69272 0.717287C8.01181 0.590723 9.3577 0.5 10.5 0.5C11.6423 0.5 12.9882 0.590723 14.3073 0.717287C15.6572 0.846813 16.6186 0.940193 17.3717 1.1212C18.1036 1.29712 18.6002 1.54781 19.0262 1.9738C19.4522 2.39979 19.7029 2.89638 19.8788 3.62831C20.0598 4.38139 20.1532 5.34276 20.2827 6.69272C20.4093 8.01181 20.5 9.3577 20.5 10.5C20.5 11.6423 20.4093 12.9882 20.2827 14.3073C20.1532 15.6572 20.0598 16.6186 19.8788 17.3717C19.7029 18.1036 19.4522 18.6002 19.0262 19.0262C18.6002 19.4522 18.1036 19.7029 17.3717 19.8788C16.6186 20.0598 15.6572 20.1532 14.3073 20.2827C12.9882 20.4093 11.6423 20.5 10.5 20.5C9.3577 20.5 8.01181 20.4093 6.69272 20.2827C5.34276 20.1532 4.38139 20.0598 3.62831 19.8788C2.89638 19.7029 2.39979 19.4522 1.9738 19.0262C1.54781 18.6002 1.29712 18.1036 1.1212 17.3717C0.940193 16.6186 0.846813 15.6572 0.717287 14.3073Z"
            stroke={textColor}
          />
          <path
            d="M14.5 10.5L10.5 6.5L6.5 10.5"
            stroke={textColor}
            strokeWidth="1.2"
          />
          <path d="M10.5 7V14" stroke={textColor} />
        </svg>
        <p style={{ color: textColor }}>{afterText}</p>
      </div>
    </div>
  )
}

export default ScrollBtn
